<template>
	<div class="order_wrap">
		<v-container class="order_home px-6">
			<div class="txt_box mt-8">
				<p>
					<span class="txt_body1">
						고객님의 계약서입니다. 계약서 열람 비밀번호는 생년월일 6자리입니다.
						ex. 890502
					</span>
				</p>
			</div>
			<div class="button_box  mt-9">
				<div class="tit_03">계약서 목록</div>
				<template v-for="(ord, i) in orderList">
					<div class="list_vertical_01" :key="i" @click="downloadEc(ord)">
						<div class="label">주문번호 {{ ord.orderNo }}</div>
						<div class="btn_type_01">
							<p class="btn_tit">
								<strong class="primary--text">{{ ord.sellType }}</strong>
								{{ ord.prdNameKr }}
							</p>
							<span class="txt_body1">
								{{ ord.prdName }}
							</span>
						</div>
					</div>
				</template>
			</div>
		</v-container>
	</div>
</template>
<script>
import {
	fetchOrderConfirm,
	fetchEContractBySapOrderNo
} from '@/apis/order.ec.api'
import { getPrdName } from '@/utils/ec'
export default {
	data() {
		return {
			name: '',
			orderList: [],
			custType: ''
		}
	},
	async mounted() {
		const { orderNo } = this.$route.query

		const ecRes = await fetchEContractBySapOrderNo({ orderNo })
		const orderNoList = ecRes.resultObject.data[0].setup

		orderNoList.forEach(ordNo => {
			let ord = {}
			ord.orderNo = ordNo.ORDER_NO
			ord.prdName = ordNo.GOODS_NM
			ord.prdNameKr = this.getPrdName(ordNo.PRODH9)
			ord.sellType = ordNo.E_SALE_GUBUN
			this.orderList.push(ord)
		})
	},
	methods: {
		getPrdName,
		async downloadEc(ord) {
			let params = {
				orderNo: ord.orderNo
			}
			const res = await fetchOrderConfirm(params)
			const link = `${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqClientDown/pdfDown.jcg?filePath=${res.resultObject.data.ET_ZSDS5232[0].ZPDF_PATH}&fileName=ENC_${res.resultObject.data.ET_ZSDS5232[0].ZPDF}`
			this.$log(link)
			location.href = link
		}
	}
}
</script>
